import React from 'react';
import '../../assets/styles/howItWorks.css';
import ProcessImage from '../../assets/images/illustrateprocess.svg'
import Process1 from '../../assets/images/proces1.svg'
import Process2 from '../../assets/images/proces2.svg'
import Process3 from '../../assets/images/proces3.svg'

import {useNavigate} from 'react-router';
import { useEffect } from 'react';

const HowitWorks = () => {

  const navigate = useNavigate ();


  const howFarmsellWorkRedirect = () => {

    
    navigate ('/HowItWorks');
  };

  return (
    <div>
      <div className='how-it-works'>

        <div className='left-works'>
          <h1 className='title-works'>How Farmsell <span className='hero-span'>Works </span></h1>
          <p className='p-works'>Step into a new era of agricultural trade with Farmsell. Our platform redefines how buyers 
          and sellers connect, offering a streamlined journey marked by smart AI matching, transparent communication, and 
          optional buying adverts for unparalleled efficiency.</p>

          <div className='bullets-works1'>
            <img src={Process1}/>
            <p className='p-bull'>Join Farmsell by registering on our user-friendly app</p>
          </div>

          <div className='bullets-works2'>
            <img src={Process2} />
            <p className='p-bull'>Farmsell's AI smartly matches sellers and buyers based on profiles and ....</p>
          </div>

          <div className='bullets-works3'>
            <img  src={Process3}/>
            <p className='p-bull'>Farmsell's AI smartly matches sellers and buyers based on profiles and ....</p>
          </div>

          <button className='but-works'  onClick={()=>howFarmsellWorkRedirect()}>Check out our process</button>
          
        </div>

        <div className='right-works'>
          <img className='work-image' src={ProcessImage}/>
          
          </div>
      </div>

    </div>
  )
}

export default HowitWorks
