import React, { useState } from 'react';
import '../../assets/styles/header.css';
import Logo from '../../assets/images/Header.svg';
import HamburgerIcon from '../../assets/images/Menu.svg';
import { useNavigate } from 'react-router';
import Sidebar from '../HarmbugMenu';
import { Link as ScrollLink } from 'react-scroll';

const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const navigate = useNavigate();

  const redirectToPage = (redirect) => {
    navigate(redirect);
    // setMenuOpen(!menuOpen);
  };
  const How = () => {
    navigate('/how-we-work');
  };

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <div className="our-header">
      <Sidebar isMenu={menuOpen} setMenu={() => toggleMenu()} />
      <header className="main-header shadow header-main-web">
        <div>
          <img
            src={Logo}
            alt="logo"
            className="logo"
            style={{ width: '13%', cursor: 'pointer' }}
            onClick={() => redirectToPage('/')}
          />
          <nav className="nav" style={{ marginLeft: '99px' }}>
            <ul>
              <li onClick={() => redirectToPage('/')}>Home</li>
              <ScrollLink to='service' smooth duration={900}><li>Services</li></ScrollLink>
              <li onClick={() => How()}>How We Work</li>
              <li onClick={() => navigate('/about-us')}>About Us</li>
            </ul>
          </nav>
          <button
            className="join-btn web"
            onClick={() => redirectToPage('/join-farmsell')}
          >
            Join Now, Its Free
          </button>
          <button className="join-btn mobile">Go to farmsell.org</button>
          <img
            src={HamburgerIcon}
            alt="hamburger"
            className="hamburger"
            onClick={toggleMenu}
            style={{ cursor: 'pointer' }}
          />
        </div>
      </header>

      <header className="main-header shadow header-main-mobile">
        <div className="header-mobile-home-container">
          <img
            src={Logo}
            alt="logo"
            className="logo"
            style={{ width: '35%' }}
          />
          <button
            className="join-btn mobile"
            style={{ marginLeft: 'auto' }}
            onClick={() => redirectToPage('/join-farmsell')}
          >
            Join Now
          </button>
          <img
            src={HamburgerIcon}
            alt="hamburger"
            style={{ height: '100%', marginLeft: 8 }}
            onClick={toggleMenu}
          />
        </div>
      </header>
    </div>
  );
};

export default Header;
