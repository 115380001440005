import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../../assets/styles/ImagePopup/ImagePopup.css';
import mainImage from '../../assets/images/ImagePopup/Mans.png';
import CloseImage from '../../assets/images/ImagePopup/dfclose.png';

const ImagePopup = ({ isOpen, onClose }) => {
    const navigate = useNavigate();

    useEffect(() => {
        let timer;
        if (isOpen) {
            // Disable scrolling on the body element when the popup is open
            document.body.style.overflow = 'hidden';
        }

        // Clear the timer and re-enable scrolling when the popup is closed
        return () => {
            clearTimeout(timer);
            document.body.style.overflow = 'auto';
        };
    }, [isOpen]);

    const handleClose = () => {
        onClose(); // Close the popup
    };

    const registerWithFarmsll = (Funder) => {
        navigate(`/register/${Funder}`, { state: { role: Funder } });
        onClose(); // Close the popup
    };

    return (
        <div className={`image-popup ${isOpen ? 'open' : ''}`}>
            <div className="pop-content">
                <img src={mainImage} alt="Popup Image" className="popup-image" />
                <div className="popup-text">
                    <h2 className='hiuhs'>Join Farmsell Today</h2>
                    <p className='bhffts'>
                        Join Farmsell Today – Unleash the
                        Potential of Your Agricultural Transactions!
                    </p>
                    <button onClick={() => registerWithFarmsll('Funder')} className="join-us">Join Now, Its Free</button>
                </div>
                <img src={CloseImage} alt="Close" className="close-button" onClick={handleClose} />
            </div>
        </div>
    );
};

export default ImagePopup;
