import '../../assets/styles/testimonialPage.css';
import React from 'react';
import Carousel from 'better-react-carousel';
import Alice from '../../assets/images/Testimonies/Alice.jpeg';
import Bob from '../../assets/images/Testimonies/Bob.jpeg';
import Char from '../../assets/images/Testimonies/char.jpg';
import Daniel from '../../assets/images/Testimonies/daniel.jpg';
import Grace from '../../assets/images/Testimonies/grace.jpg';
import AliceK from '../../assets/images/Testimonies/AliceK.jpg';
import John from '../../assets/images/Testimonies/john.jpg';
import Okell from '../../assets/images/Testimonies/okell.jpg';


// const MyDot = ({isActive}) => (
//   <span
//     style={{
//       display: 'inline-block',
//       height: isActive ? '8px' : '5px',
//       width: isActive ? '8px' : '5px',
//       background: '#1890ff',
//     }}
//   />
// );

const TestimonialPage = () => {
  return (
    <div>

      <div className="testimonialContainer">
        <h1>
          <span>Revolutionizing </span> Agriculture with
          <br />
          Farmsell. What our <span>customers</span> Say
        </h1>
        <div className="desc-text">
          Welcome to the agricultural transformation powered by Farmsell, where every transaction becomes a testament to seamless efficiency and enhanced profitability. Our users aren't just experiencing a platform; they're participating in a revolution
        </div>
      </div>

      <div className="carousel-testimony">
        <Carousel
          cols={2}
          rows={1}
          gap={10}
          dotColorActive={'rgba(63, 184, 113, 1)'}
          dotColorInactive={'rgba(199, 199, 199, 1)'}
          showDots={true}
          hideArrow={true}
          autoplay={9000}
          loop
        >
          <Carousel.Item>
            <div className="carousel-item-testimony">
              <div className="top-item-testimony">
                <img src={Alice} className="img-testimony" alt='testimony member' />
                <div className='name-title'>
                  <h5 style={{marginTop: -8, fontSize:'20px', fontWeight:"600"}}>Alice </h5>

                  <p style={{marginTop: -8, color: '#3fb871', fontSize:'15px'}}>
                  A tomato farmer in Uganda
                  </p>
                </div>
              </div>
              <div className="bottom-item-testimony">
                "Farmsell has been a game-changer for my farming business. I used to struggle to find buyers for my products and 
                often had to sell them at low prices or let them go to waste. But with Farmsell, I can easily list my products and get matched with buyers who are willing to pay fair prices. 
                I have increased my income and improved my livelihood thanks to Farmsell."
              </div>
            </div>
          </Carousel.Item>

          <Carousel.Item>
            <div className="carousel-item-testimony">
              <div className="top-item-testimony">
                <img src={Bob} className="img-testimony" alt='testimony member' />
                <div className='name-title'>
                  <h5 style={{marginTop: -8, fontSize:'20px', fontWeight:"600"}}>Bob</h5>

                  <p style={{marginTop: -8, color: '#3fb871', fontSize:'15px'}}>
                  A food factory manager in Kenya
                  </p>
                </div>
              </div>
              <div className="bottom-item-testimony">
              “Farmsell has been a great partner for my food factory. I used to source my raw materials from different suppliers and often 
              faced issues with quality, quantity, and delivery. But with Farmsell, I can find and buy quality and affordable agricultural products
               from verified sellers. I have reduced my costs and improved my productivity thanks to Farmsell."
              </div>
            </div>
          </Carousel.Item>

          <Carousel.Item>
            <div className="carousel-item-testimony">
              <div className="top-item-testimony">
                <img src={Char} className="img-testimony" alt='testimony member' />
                <div className='name-title'>
                  <h5 style={{marginTop: -8, fontSize:'20px', fontWeight:"600"}}>Charles </h5>

                  <p style={{marginTop: -8, color: '#3fb871', fontSize:'15px'}}>
                  An impact investor in South Africa
                  </p>
                </div>
              </div>
              <div className="bottom-item-testimony">
              "Farmsell has been a valuable resource for my investment portfolio. I used to have a hard time finding and 
              evaluating sustainable agricultural projects to invest in. But with Farmsell, I can discover and support impactful 
              and innovative agricultural initiatives in Africa. I have diversified my investments and enhanced my returns thanks to Farmsell."
              </div>
            </div>
          </Carousel.Item>

          <Carousel.Item>
            <div className="carousel-item-testimony">
              <div className="top-item-testimony">
                <img src={John} className="img-testimony" alt='testimony member' />
                <div className='name-title'>
                  <h5 style={{marginTop: -8, fontSize:'20px', fontWeight:"600"}}>John Mbeki </h5>

                  <p style={{marginTop: -8, color: '#3fb871', fontSize:'15px'}}>
                 Smallholder Farmer
                  </p>
                </div>
              </div>
              <div className="bottom-item-testimony">
              "Farmsell has Transformed the way I sell my produce. No more dealing with middlemen or traveling long distances to find buyers. With
               Farmsell, I connect directly with buyers, ensuring fair prices and reducing post-harvest losses. Farmsell is a real game-changer for small farmers like me!"
              </div>
            </div>
          </Carousel.Item>

          <Carousel.Item>
            <div className="carousel-item-testimony">
              <div className="top-item-testimony">
                <img src={AliceK} className="img-testimony" alt='testimony member' />
                <div className='name-title'>
                  <h5 style={{marginTop: -8, fontSize:'20px', fontWeight:"600"}}>Alice Kariuki</h5>

                  <p style={{marginTop: -8, color: '#3fb871', fontSize:'15px'}}>
                  Agri-Input Business Owner
                  </p>
                </div>
              </div>
              <div className="bottom-item-testimony">
              "Managing my agri-input business has never been easier. Farmsell's smart-matching capabilities streamline the selling process.
               The AI-driven platform connects me with farmers in need of quality inputs, 
              making transactions efficient and profitable. Farmsell is my go-to solution for seamless B2B transactions."
              </div>
            </div>
          </Carousel.Item>

          <Carousel.Item>
            <div className="carousel-item-testimony">
              <div className="top-item-testimony">
                <img src={Grace} className="img-testimony" alt='testimony member' />
                <div className='name-title'>
                  <h5 style={{marginTop: -8, fontSize:'20px', fontWeight:"600"}}>Grace Mwangi</h5>

                  <p style={{marginTop: -8, color: '#3fb871', fontSize:'15px'}}>
                  Procurement Manager, Coralloid Agro-Industry
                  </p>
                </div>
              </div>
              <div className="bottom-item-testimony">
              "Farmsell has significantly improved our supply chain efficiency. As a procurement manager, having a dedicated platform for
               agricultural transactions is a game-changer. Farmsell's exclusive focus on 
              agriculture ensures that we can easily source raw materials, making our supply chain more reliable and cost-effective."
              </div>
            </div>
          </Carousel.Item>

          <Carousel.Item>
            <div className="carousel-item-testimony">
              <div className="top-item-testimony">
                <img src={Daniel} className="img-testimony" alt='testimony member' />
                <div className='name-title'>
                  <h5 style={{marginTop: -8, fontSize:'20px', fontWeight:"600"}}>Daniel K. Nsubuga</h5>

                  <p style={{marginTop: -8, color: '#3fb871', fontSize:'15px'}}>
                  Director, Green Solutions Limited
                  </p>
                </div>
              </div>
              <div className="bottom-item-testimony">
              "Being a small business owner in the agricultural sector comes with its challenges. Farmsell has been instrumental in expanding my 
              customer base. The platform's user-friendly interface makes it easy for me to showcase my products, connect with buyers, 
              and grow my business. It's the perfect solution for agri-input enterprises like mine."
              </div>
            </div>
          </Carousel.Item>

          <Carousel.Item>
            <div className="carousel-item-testimony">
              <div className="top-item-testimony">
                <img src={Okell} className="img-testimony" alt='testimony member' />
                <div className='name-title'>
                  <h5 style={{marginTop: -8, fontSize:'20px', fontWeight:"600"}}>Samuel Okello</h5>

                  <p style={{marginTop: -8, color: '#3fb871', fontSize:'15px'}}>
                  Hotel Owner
                  </p>
                </div>
              </div>
              <div className="bottom-item-testimony">
              "Farmsell has become an invaluable partner for our hotel's sourcing needs. As a hotel owner, securing quality produce
               is crucial, and Farmsell has simplified the entire procurement process. The platform's diverse network of farmers ensures we get the freshest ingredients, contributing to
               the high standards we maintain in our culinary offerings. Farmsell is a game-changer for the hospitality industry!"
              </div>
            </div>
          </Carousel.Item>
        
        

        
          {/* ... */}
        </Carousel>
        <div />

        <div>
          
        </div>

      </div>
    </div>
  );
};

export default TestimonialPage;
